import axios from '@/shared/axios';
import {
  DashboardInsMediaDetailModel,
  DashboardInsMediaModel,
  StoryDetailDataModel,
} from '@/types/dashboard';
import { BaseResponse } from '@/types/web.interface';
import moment from 'moment-timezone';

export async function getInsStoryDashboardData() {
  const res = await axios.get<BaseResponse<DashboardInsMediaModel[]>>(`/ins/story/dashboard`, {
    headers: {
      'Time-Zone': localStorage.getItem('timezone') || moment().format('Z'),
    },
  });
  return res.data;
}

export async function addInsStory(media_name?: any, story_sub_id?: number) {
  const res = await axios.post<BaseResponse<DashboardInsMediaModel[]>>(`/ins/story/dashboard/add`, {
    media_name,
    story_sub_id,
  });
  return res.data;
}

export async function getInsStoryDashboardDetailData(mediaName?: string) {
  const res = await axios.get<BaseResponse<DashboardInsMediaDetailModel>>(
    `/ins/story/dashboard/account/${mediaName}`,
    {
      headers: {
        'Time-Zone': localStorage.getItem('timezone') || moment().format('Z'),
      },
    },
  );
  return res.data;
}

export async function getInsStoryDownload(
  mediaName?: string,
  type?: 'single' | 'batch',
  date_list?: string,
) {
  const res = await axios.get<BaseResponse<string>>(`/ins/story/download/${mediaName}/${type}`, {
    params: {
      date_list,
    },
  });
  return res.data;
}

export async function getInsStoryDashboardDateData(mediaName?: string) {
  const res = await axios.get<BaseResponse<DashboardInsMediaDetailModel>>(
    `/ins/story/dashboard/account/${mediaName}`,
  );
  return res.data;
}

export async function getInsStoryDashboardSearchDate(mediaName?: string, date_list?: any) {
  const res = await axios.get<BaseResponse<StoryDetailDataModel>>(
    `/ins/story/dashboard/search/${mediaName}`,
    {
      headers: {
        'Time-Zone': localStorage.getItem('timezone') || moment().format('Z'),
      },
      params: {
        date_list,
      },
    },
  );
  return res.data;
}

// 刷新内容 /ins/story/dashboard/{mediaName}/refresh
export async function getInsStoryDashboardRefresh(mediaName?: string) {
  const res = await axios.post<BaseResponse<StoryDetailDataModel>>(
    `/ins/story/dashboard/${mediaName}/refresh`,
  );
  return res.data;
}

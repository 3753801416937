import { message } from 'antd';
import { debounce, forEach } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import { fetchInsMonitorSearch } from '@/lib/service';
import { extractInstagramUsername, isInstagramProfileURL } from '@/shared/utils';
import { InsAccountModel } from '@/types/home';
import CommonSimpleModal from '../Common/CommonSimpleModal';
import {
  InsAccountModelToInsMonitorModel,
  setRecentSearchReportContent,
} from './RecentSearchReportContent';
import AddAccountContent from '../LandingPage/AddAccountContent';

const useSearchAccountGuide = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accountModel, setAccountModel] = useState<InsAccountModel>();
  const [openPrivateAccount, setOpenPrivateAccount] = useState<any>(null);

  // 跳转至未解锁报告页
  const navigateToNotActiveReport = (mediaName: string) => {
    router.push(`/analytics/not-active/${mediaName}`);
  };

  const showInvalidNameAlert = () => {
    setOpenPrivateAccount({
      title: t('Invalid Instagram Username'),
      desc: t('The username you entered is not valid. Please check the username and try again.'),
      btnTitle: t('TRYAGAIN'),
    });
  };

  const onSearch = useCallback(
    debounce((value: string) => {
      if (!value) {
        message.error(t('Please enter the insert link or username'));
        return;
      }
      // 处理输入的账户名，去除前后空格，并转换为小写
      value = (value || '').trim().toLowerCase();
      if (isInstagramProfileURL(value)) {
        value = extractInstagramUsername(value);
      }

      setLoading(true);
      fetchInsMonitorSearch(value, router.locale)
        .then((res) => {
          if (res.code == 0 && res.data?.length > 0) {
            let tempData: InsAccountModel;
            forEach(res.data, (item) => {
              if (item.media_name === value) {
                tempData = item;
              }
            });
            if (tempData !== undefined) {
              // setUserData([]);
              if (tempData?.is_private === 2) {
                showInvalidNameAlert();
              } else if (tempData?.is_private === 0) {
                // 如果是正常账号，且不是在弹窗中搜索的
                if (!false) {
                  // 保存到最近未解锁报告列表
                  setRecentSearchReportContent(InsAccountModelToInsMonitorModel([tempData]));
                  // 跳转至未解锁报告页
                  navigateToNotActiveReport(tempData?.media_name);
                } else {
                  // 如果正在添加未添加账户，则使用原有流程
                  // setHasAccount(true);
                  // setAccountModel(tempData);
                }
              } else {
                // 如果是私密账号，显示弹窗
                setAccountModel(tempData);
                // setHasAccount(true);
                // toggle(true);
                setOpenModal(true);
              }
            } else {
              if (false) {
                // 如果弹窗打开，则使用原有流程，不处理
                // setUserData(res.data);
              } else {
                // 跳过阶段一，暂时忽略多个用户的情况，未精确匹配到用户时，直接提示无效
                // setUserData([]);
                showInvalidNameAlert();
              }
            }
          } else if (res?.code === 10001) {
            showInvalidNameAlert();
          } else {
            message.error(res.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    }, 500),
    [],
  );

  // 渲染添加账号弹窗，目前仅在阶段二使用，用于展示私密账号的提示信息
  const renderAddAccountModal = () => {
    return (
      <AddAccountContent
        open={openModal}
        accountData={accountModel}
        ignoreStageOneSearch={true}
        onClickOpen={(isOpen) => setOpenModal(isOpen)}
      />
    );
  };

  const renderPrivateAccountModal = () => {
    return (
      <CommonSimpleModal
        open={openPrivateAccount !== null}
        title={openPrivateAccount?.title}
        desc={openPrivateAccount?.desc}
        btnTitle={openPrivateAccount?.btnTitle}
        closeAction={(isOk: boolean) => {
          setOpenPrivateAccount(null);
        }}
      />
    );
  };

  return {
    searchAccountLoading: loading,
    onSearchAccount: onSearch,
    renderAddAccountModal,
    renderPrivateAccountModal,
  };
};

export default useSearchAccountGuide;

import CommonSimpleModal from '@/components/Common/CommonSimpleModal';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styles from './index.module.less';

export const LimitAlert = ({
    open,
    closeAction,
}:{
    open?:boolean;
    closeAction?:(isOk?:boolean)=>void;
}) => {
    const { t } = useTranslation()

    return <CommonSimpleModal
        open={open}
        title={
            <div className={styles.title}>
                {t("You’ve reached your free limit")}
            </div>
        }
        desc={<div className={styles.infoContent}>
            <p className={styles.desc}>
                {t("You've hit the limit for free users. Upgrade to Premium for unlimited access to:")}
            </p>
            <div className={styles.list}>
                {[
                    "✅ Unlimited viewing & downloads",
                    "✅ One-click bulk downloads",
                    "✅ Unlock hidden mentions & links"
                ].map((item)=>{
                    return <p key={item}>{t(item)}</p>
                })}
            </div>
        </div>}
        btnTitle={<div className={styles.btn}>{t("GET PREMIUM")}</div>}
        closeAction={closeAction}
    />
}

export const ExpiredAlert = ({
    open,
    closeAction,
}:{
    open?:boolean;
    closeAction?:(isOk?:boolean)=>void;
}) => {
    const { t } = useTranslation()

    return <CommonSimpleModal
        open={open}
        title={
            <div className={styles.title}>
                {t("Your subscription has expired.")}
            </div>
        }
        desc={<div className={styles.infoContent}>
            <p className={styles.desc}>
                {t("To continue enjoying all features, please renew your plan.")}
            </p>
        </div>}
        btnTitle={t("Renew Now")}
        closeAction={closeAction}
    />
}